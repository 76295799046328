import { Component, OnInit } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '../auth.service';
import { ForgotPasswordRequestModel } from '../auth.model';
import { StorageService } from '@services/storage.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  isProcessing:boolean = false;  
  
  forgotPasswordRequestModel: ForgotPasswordRequestModel;
  defaultRequest: ForgotPasswordRequestModel = {
    email: "admin@ClassKeeper.com",
  };
  isRequestSuccess: boolean = false;

  constructor(
    private authService: AuthService, 
    private storageservice: StorageService,
    private router: Router,
    private toaster: ToastrService) 
  { 
    this.storageservice.removeItem('authorizationDataIdToken');
    this.storageservice.removeItem('user');
    this.authService.isLoggedIn = false;
  }

  ngOnInit(): void {    
    this.forgotPasswordRequestModel = this.defaultRequest;
    this.isProcessing = false;
  }

  onCancel(){
    this.router.navigate(['/account/login']);
  }

  onSubmit() {
    this.isProcessing = true;
    if(!this.forgotPasswordRequestModel.email){
      this.toaster.error("email cannot be empty");
      this.isProcessing = false;
      return;
    }

    this.authService.isLoggedIn = false;

    this.authService.forgotPassword(this.forgotPasswordRequestModel)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        if (res.isSuccess) {
          this.isRequestSuccess = true;
        }
        this.isProcessing = false;
    });
  }
  
  private handleError(error: any) {
    this.isProcessing = false;
    if(error.status === 0 && error.statusText === 'Unknown Error'){
      return throwError(error);
    }
    else if(error.error.message === "Input validation failed!"){
      this.toaster.error(error.error.errors[0]);
    }
    else{
      this.toaster.error(error.error.message);
    }
    return throwError(error);
  }
}
