import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

var moment = require("moment/moment");
declare var require: (arg0: string) => any;

@Component({
  selector: "app-dropdown-button",
  templateUrl: "./dropdown-button.component.html",
  styleUrls: ["./dropdown-button.component.scss"],
})
export class DropdownButtonComponent implements OnInit {
  @Input() optionCustom: boolean = true;
  daterangepicker: boolean = false;
  dates: any[] = [];
  // tillDate: any[] = [];
  value: number;

  @Output() newItemEvent: EventEmitter<number> = new EventEmitter<number>();

  constructor() {}

  ngOnInit(): void {}

  // *********************SELECT OPTION*********************************************

  onclick(event) {
    this.value = event.target.value;
    this.newItemEvent.emit(this.value);
    console.log(this.value);
    // if (event.target.value == "4") {
    //   this.getCustomDateData();
    // }

    // if (event.target.value == "1") {
    //   this.getThisMonthData();
    // }
    // if (event.target.value == "2") {
    //   this.getLastMonthData();
    // }
    // if (event.target.value == "3") {
    //   this.getYearToDateData();
    // }
    // this.value = event.target.value;
    // this.newItemEvent.emit(this.value);
  }

  // *********************THIS MONTH DATA FUNCTION*********************************************

  getThisMonthData() {
    this.newItemEvent.emit(this.value);
  }
  // *********************DATE TO STRING*********************************************

  convertDate(date) {
    return moment(date).format("YYYY-MM-DD");
  }
  // *********************LAST MONTH FUNCTION*********************************************

  // getLastMonthData() {
  //   var todayDate = new Date();

  //   var lastMonthDate = new Date(todayDate);
  //   lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);

  //   var firstDay = new Date(
  //     lastMonthDate.getFullYear(),
  //     lastMonthDate.getMonth(),
  //     1
  //   );
  //   var lastDay = new Date(
  //     lastMonthDate.getFullYear(),
  //     lastMonthDate.getMonth() + 1,
  //     0
  //   );

  //   const firstDateString = this.convertDate(firstDay);
  //   const lastDateString = this.convertDate(lastDay);

  //   // console.log(firstDateString);
  //   // console.log(lastDateString);
  //   this.dates[0] = firstDateString;
  //   this.dates[1] = lastDateString;
  //   this.newItemEvent.emit(this.dates);
  // }

  // *********************YEAR TO DATE FUNCTION*********************************************

  // getYearToDateData() {
  //   var currentDate = new Date();

  //   // var currentDate = "2020 / 2 / 29 ";

  //   var makeDate = new Date(currentDate);

  //   makeDate.setFullYear(makeDate.getFullYear() - 1);
  //   // console.log(makeDate);
  //   const currentYear = this.convertDate(currentDate);
  //   const lastYear = this.convertDate(makeDate);
  //   // console.log(lastYear);
  //   // console.log(currentYear);
  //   this.dates[0] = lastYear;
  //   this.dates[1] = currentYear;
  //   this.newItemEvent.emit(this.dates);
  // }
  // *********************CUSTOM FUNCTION*********************************************

  // getCustomDateData() {
  //   var startDate = null;
  //   var endDate = null;
  //   // console.log(startDate);
  //   // console.log(endDate);
  //   this.dates[0] = startDate;
  //   this.dates[1] = endDate;
  //   this.newItemEvent.emit(this.dates);
  // }
}
