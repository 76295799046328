import {
  AfterContentInit,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";

declare var require: any;
let Knob = require("knob"); // browserify require

@Component({
  selector: "app-knob-chart",
  templateUrl: "./knob-chart.component.html",
  styleUrls: ["./knob-chart.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class KnobChartComponent implements OnInit, AfterContentInit {
  @Input() value: number = 0;
  @Input() maxValue: number = 100;
  @Input() size: string = "100";
  @Input() fgColor: string = "#7366ff";
  @Input() bgColor: string = "#eef5fb";
  @Input("unit") label: string = "";
  @Input() textColor: string = null;
  @Input() subTextColor: string = "#ff0000";
  @Input() textFontSize: string = null;
  @Input() subTextFontSize: string = null;
  @Input() thickness: number = 0.15;
  @Input() displayInput: boolean = true;

  knob: any;

  constructor() {}
  ngAfterContentInit(): void {
    const el = document.getElementById("knob");
    if (this.textColor) el.querySelector("input").style.color = this.textColor;
    if (this.textFontSize)
      el.querySelector("input").style.fontSize = this.textFontSize;
    //el.querySelector("span").style.color = this.subTextColor;
    if (this.subTextFontSize)
      el.querySelector("span").style.fontSize = this.subTextFontSize;
  }

  ngOnInit(): void {
  }

  ngOnChanges(){
    if(this.knob != undefined){
    var a = document.getElementById("knob").childNodes[0].remove();
    }
    this.knob = Knob({      
      width: this.size,
      height: this.size,
      max: this.maxValue,
      min:0,
      step: 1,
      // lineCap: "round",
      fgColor: this.fgColor,
      bgColor: this.bgColor,
      className: "knob-content-center",
      activeClass: "knob-block text-center",
      value: this.value,
      readOnly: true,
      thickness: this.thickness,
      label: this.label,
      labelColor: this.subTextColor,
      displayInput: this.displayInput,

      angleOffset: 0,
      angleArc: 360,
    });
    document.getElementById("knob").append(this.knob);
  }
}
