<div ngbDropdown>
    <button type="button" class="btn btn-add nav-pills" id="downloadBtn" ngbDropdownToggle [disabled]="isDownloading">
        <img src="assets/images/icons/downloading.gif" alt="" *ngIf="isDownloading">
        <img src="assets/images/icons/download.svg" alt="" *ngIf="!isDownloading">
        <span>{{ isDownloading ? "Downloading..." : "Download" }}</span>
    </button>
    <div ngbDropdownMenu aria-labelledby="downloadBtn" style="width: 100%;">
        <button ngbDropdownItem (click)="downloadAsXLSX()">XLSX</button>
        <button ngbDropdownItem (click)="downloadAsPDF()">PDF</button>
        <button ngbDropdownItem (click)="downloadAsCSV()">CSV</button>
    </div>
</div>

<!-- <div class="downloadbtn" *ngIf="false">
    <button class="btn btn-add nav-pills" (click)="downloadAsXLSX()"><img src="assets/images/icons/download.svg" alt=""><span>as XLSX</span></button>
    <button class="btn btn-add nav-pills" (click)="downloadAsPDF()"><img src="assets/images/icons/download.svg" alt=""><span>as PDF</span></button>
</div> -->