import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ResponseModel } from '@models/response.model';
import { DataService } from '@services/data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isLoggedIn:boolean = false;
  
  constructor(private service: DataService) {
  }

  // sign in
  signIn(data: any): Observable<ResponseModel> {
    let url = '/identityapi/Admin/Account/AdminLogin';
    return this.service.post(url, data, false).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  // forgot password
  forgotPassword(data: any): Observable<ResponseModel> {
    let url = '/identityapi/Admin/Account/ForgotPassword';
    return this.service.post(url, data, false).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  }

  // reset password
  resetPassword(data: any): Observable<ResponseModel> {
    let url = '/identityapi/Admin/Account/ResetPassword';
    return this.service.post(url, data, false).pipe<ResponseModel>(tap((response: any) => {
      return response;
    }));
  } 
}
