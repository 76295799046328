import { Directive, AfterContentChecked } from '@angular/core';
import { DatatableComponent } from "@swimlane/ngx-datatable";

@Directive({
  selector: '[NgxdtResizer]'
})
export class NgxdtResizerDirective implements AfterContentChecked {

  constructor(private table: DatatableComponent) { }

  private latestWidth: number;

  ngAfterContentChecked() {    
    setTimeout(() => {
      if (this.table && this.table.element.clientWidth !== this.latestWidth) {
        this.latestWidth = this.table.element.clientWidth;
        this.table.recalculate();
      }
    }, 100);
  }

}
