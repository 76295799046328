<div class="modal-body">
    <div class='confirmation-text' [ngSwitch]="confirmationModalTemplate">
        <div *ngSwitchCase=modalTemplate.ActivateUser>
            Are you sure you want to activate<br />
            the user <span>{{data.username}}</span>?
        </div>
        <div *ngSwitchCase=modalTemplate.DeactivateUser>
            Are you sure you want to deactivate<br />
            the user <span>{{data.username}}</span>?
        </div>
        <div *ngSwitchCase=modalTemplate.ExtendDownloadTime>
            Are you sure you want to extend the time frame<br />
            for downloading data of the user <span>{{data.username}}</span>?
        </div>
        <div *ngSwitchCase=modalTemplate.ResetPassword>
            Are you sure you want to reset password for
            the user <span>{{data.username}}</span>?
        </div>
        <div *ngSwitchDefault>

        </div>
    </div>
    <div class="action-buttons">
        <button type="button" class="btn btn-green" data-dismiss="modal" (click)="closeModal(modalAction.Yes)">Yes</button>
        <button type="button" class="btn btn-darkblue ml-2 ml-md-4" (click)="closeModal(modalAction.No)">No</button>
        <button type="button" class="btn btn-outline-darkblue ml-2 ml-md-4"
            (click)="closeModal(modalAction.Cancel)">Cancel</button>
    </div>
</div>