<div class="auth-component-wrapper">
  <div class="container-fluid px-0 px-md-4 px-lg-5 py-0 py-md-3 py-lg-4">
    <div class="card">
      <div class="card-body p-3 p-md-3 p-lg-4">
        <div class="auth-form-wrapper">
          <div class="logo">
            <img
              class="img-fluid for-light p-1"
              src="assets/images/ck/logo-lg.png"
              alt="login"
              width="360"
            />
            <img
              class="img-fluid for-dark p-1"
              src="assets/images/ck/logo-lg.png"
              alt="login"
              width="360"
            />
          </div>
          <div class="auth-form px-3 px-md-4 px-xl-4 py-4 py-xl-4">
            <div class="p-xl-3 p-md-0">
              <form class="theme-form needs-validation">
                <div class="form-header">
                  <h2 class="form-title">Sign in to account</h2>
                  <small class="form-subtitle"
                    >Enter your email & password to log in.</small
                  >
                </div>
                <div class="form-group">
                  <label>Email Address<sup>*</sup></label>
                  <input
                    class="form-control"
                    type="email"
                    name="email"
                    [(ngModel)]="loginModel.email"
                    required
                    placeholder="Email address*"
                  />
                </div>
                <div class="form-group">
                  <label>Password<sup>*</sup></label>
                  <div class="form-control password-control">
                    <input
                      #password
                      [type]="hidePassword ? 'password' : 'text'"
                      name="password"
                      placeholder="Password*"
                      [(ngModel)]="loginModel.password"
                      required
                    />
                    <button (click)="hidePassword = !hidePassword">
                      <app-feather-icons
                        *ngIf="hidePassword"
                        icon="eye"
                      ></app-feather-icons>
                      <app-feather-icons
                        *ngIf="!hidePassword"
                        icon="eye-off"
                      ></app-feather-icons>
                    </button>
                  </div>
                </div>
                <div class="form-group">
                  <div>
                    <label class="col-form-label d-flex align-items-center p-0" for="checkbox1">
                      <input id="checkbox1" name="rememberMe" class="mr-1 bg-dark" [(ngModel)]="loginModel.rememberMe"
                        [checked]="loginModel.rememberMe" type="checkbox">
                      <span>Remember me</span>
                    </label>
                  </div>
                  </div>
                <div class="form-link">
                  <a routerLink="/account/forgot-password">
                    Forgot password?
                  </a>
                </div>
                <div class="form-buttons">
                  <button
                    (click)="onSubmit()"
                    [disabled]="isProcessing"
                    class="btn btn-blue"
                    type="submit"
                  >
                    <span>{{ isProcessing ? "Signing" : "Sign In" }}</span>
                  </button>
                  <button
                    (click)="onReset()"
                    [disabled]="isProcessing"
                    class="btn btn-clear"
                    type="reset"
                  >
                    <span>Cancel</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
