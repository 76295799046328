<div class="auth-component-wrapper">
    <div class="container-fluid px-0 px-md-4 px-lg-5 py-0 py-md-3 py-lg-4">
        <div class="card">
            <div class="card-body p-3 p-md-3 p-lg-4">
                <div class="auth-form-wrapper">
                    <div class="logo">
                        <img class="img-fluid for-light p-1" src="assets/images/ck/logo-lg.png" alt="login" width="360">
                        <img class="img-fluid for-dark p-1" src="assets/images/ck/logo-lg.png" alt="login" width="360">
                    </div>
                    <div class="auth-form px-3 px-md-4 px-xl-4 py-4 py-xl-4">
                        <div class="p-xl-3 p-md-0" *ngIf="!isRequestSuccess">
                            <form class="theme-form needs-validation">
                                <div class="form-header">
                                    <h2 class="form-title">Forgot Password</h2>
                                    <small class="form-subtitle">Enter your registered email address</small>
                                </div>
                                <div class="form-group">
                                    <label>Email Address<sup>*</sup></label>
                                    <input class="form-control" type="email" name="email"
                                        [(ngModel)]="forgotPasswordRequestModel.email" required
                                        placeholder="Email address*">
                                </div>
                                <div class="form-buttons mt-5">
                                    <button (click)="onSubmit()" [disabled]="isProcessing" class="btn btn-blue"
                                        type="submit">
                                        <span>{{ isProcessing ? 'Sending...' : 'Confirm' }}</span>
                                    </button>
                                    <button (click)="onCancel()" [disabled]="isProcessing" class="btn btn-clear"
                                        type="reset">
                                        <span>Cancel</span>
                                    </button>
                                </div>

                            </form>
                        </div>
                        <div class="p-xl-3 p-md-0" *ngIf="isRequestSuccess">
                            <div class="form-header text-center">
                                <h2 class="form-title mb-4">Email sent successfully</h2>
                                <small class="form-subtitle">A reset password link has been sent on your email
                                    address.</small>
                            </div>
                            <div class="form-link active">
                                <a routerLink="/account/login">
                                    Sign In
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>