import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoggedInUserVM } from '@src/app/shared/models/user.model';
import { StorageService } from '@src/app/shared/services/storage.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit, OnDestroy {

  public user: LoggedInUserVM;
  sub$: Subscription;

  constructor(
    private storageservice: StorageService, private router: Router,) {    
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  ngOnInit() {
    this.user = this.storageservice.retrieve('user');
    this.sub$ = this.storageservice.onProfileImageUpdate$.subscribe(isChanged => {
      if (isChanged) {
        this.user = this.storageservice.retrieve('user');
      }
    });
  }
  onLogout(){
    this.storageservice.clear();
    this.router.navigate(['/account/login'])
  }
}
