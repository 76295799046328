import { LoggedInUserVM } from "@src/app/shared/models/user.model";

export interface Auth {
}
export class ForgotPasswordRequestModel {
    email: string;
}
export class ResetPasswordRequestModel {
    email: string;
    password: string;
    confirmPassword: string;
    token: string;
}
export class LoginRequestModel {
    email: string;
    password: string;
    rememberMe: boolean;
    deviceId: string;
    platformId: number;
    token: string;
}
export class LoginResponseModel {
    accessToken: string;
    scope: string;
    tokenType: string;
    refreshToken: string;
    expiresIn: number;
    user: LoggedInUserVM = new LoggedInUserVM();
}
