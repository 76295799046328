<div class="auth-component-wrapper">
    <div class="container-fluid px-0 px-md-4 px-lg-5 py-0 py-md-3 py-lg-4">
        <div class="card">
            <div class="card-body p-3 p-md-3 p-lg-4">
                <div class="auth-form-wrapper" style="max-width: 400px;">
                    <div class="logo">
                        <img class="img-fluid for-light p-1" src="assets/images/ck/logo-lg.png" alt="login" width="360">
                        <img class="img-fluid for-dark p-1" src="assets/images/ck/logo-lg.png" alt="login" width="360">
                    </div>
                    <div class="auth-form p-4 p-xl-5">
                        <div class="mt-xl-3 mb-xl-2" *ngIf="!isRequestSuccess">
                            <form class="theme-form needs-validation">
                                <div class="form-header">
                                    <h2 class="form-title">Enter New Password</h2>
                                    <small class="form-subtitle">(Only letters and numbers, 8-16 characters
                                        long)</small>
                                </div>
                                <div class="form-group">
                                    <label>Enter New Password<sup>*</sup></label>
                                    <div class="form-control password-control">
                                        <input #password [type]="hidePassword ? 'password' : 'text'" name="password"
                                            placeholder="Enter New Password*"
                                            [(ngModel)]="resetPasswordRequestModel.password" required>
                                        <button (click)="hidePassword = !hidePassword">
                                            <app-feather-icons *ngIf="hidePassword" icon="eye"></app-feather-icons>
                                            <app-feather-icons *ngIf="!hidePassword" icon="eye-off"></app-feather-icons>
                                        </button>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Confirm New Password<sup>*</sup></label>
                                    <div class="form-control password-control">
                                        <input #password [type]="hideConfirmPassword ? 'password' : 'text'"
                                            name="password" placeholder="Both passwords must match*"
                                            [(ngModel)]="resetPasswordRequestModel.confirmPassword" required>
                                        <button (click)="hideConfirmPassword = !hideConfirmPassword">
                                            <app-feather-icons *ngIf="hideConfirmPassword"
                                                icon="eye"></app-feather-icons>
                                            <app-feather-icons *ngIf="!hideConfirmPassword"
                                                icon="eye-off"></app-feather-icons>
                                        </button>
                                    </div>
                                </div>
                                <div class="form-buttons mt-5">
                                    <button (click)="onSubmit()" [disabled]="isProcessing" class="btn btn-blue"
                                        type="submit">
                                        <span>{{ isProcessing ? 'Sending...' : 'Confirm' }}</span>
                                    </button>
                                    <button (click)="onCancel()" [disabled]="isProcessing" class="btn btn-clear"
                                        type="reset">
                                        <span>Cancel</span>
                                    </button>
                                </div>

                            </form>
                        </div>
                        <div *ngIf="isRequestSuccess">
                            <div class="form-header text-center">
                                <h2 class="form-title mb-4">Password Reset Successfully</h2>
                                <small class="form-subtitle">
                                    Your password was reset successfully.<br />
                                    Please log back in using your new password.
                                </small>
                            </div>
                            <div *ngIf="showLoginButton" class="form-link active">
                                <a routerLink="/account/login">
                                    Sign In
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>