<div class="profile-wrapper">
  <div class="media profile-media">
    <img *ngIf="user.photo?.editedUrl" class="user-profile-image" [src]="user.photo?.editedUrl" alt="">
    <img *ngIf="!user.photo?.editedUrl" class="user-profile-image" src='assets/images/icons/userImage.svg' alt="">
    <div class="media-body">
      <span>{{ user?.firstName }} {{ user?.lastName }}</span>
      <p class="mb-0 font-roboto">{{ user?.userRole }}</p>
    </div>
  </div>
  <ul class="profile-dropdown onhover-show-div">
    <li><a routerLink="profile/edit"><app-feather-icons [icon]="'edit'"></app-feather-icons><span>Edit Profile</span></a></li>
    <li><a href="javascript:void(0)"  (click)="onLogout()"><app-feather-icons [icon]="'log-out'"></app-feather-icons><span>Log out</span></a></li>
  </ul>
</div>
