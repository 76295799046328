<div class="range-datepicker">
  <div class="input-group">
    <button class="btn-calendar" (click)="datepicker.toggle()" type="button">
      <img src="assets/images/icons/calender.svg" />
    </button>
    <input
      name="datepicker"
      class="input-calendar"
      placeholder="mm-dd-yyyy - mm-dd-yyyy"
      ngbDatepicker
      [value]="inputDate"
      #datepicker="ngbDatepicker"
      [autoClose]="'outside'"
      (dateSelect)="onDateSelection($event)"
      [displayMonths]="2"
      [dayTemplate]="dayTemplate"
      [footerTemplate]="footerTemplate"
      outsideDays="hidden"
      [startDate]="fromDate!"
      tabindex="-1"
      readonly="true"
      type="hidden"
    />
    <div class="date-range">
      <div class="ngxdt-date-range">
        <div class="ngxdt-date-range-wrapper">
          <input
            class="input-calendar"
            type="text"
            [value]="fromDateString"
            placeholder="MM-DD-YYYY"
            readonly="true"
          />
        </div>
      </div>
      <span><img src="assets/images/icons/right-arrow.svg" alt="" /></span>
      <div class="ngxdt-date-range">
        <div class="ngxdt-date-range-wrapper">
          <input
            class="input-calendar"
            type="text"
            [value]="toDateString"
            placeholder="MM-DD-YYYY"
            readonly="true"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Templates -->
<ng-template #dayTemplate let-date let-focused="focused">
  <span
    class="custom-day"
    [class.focused]="focused"
    [class.range]="isRange(date)"
    [class.start-date]="isStartDate(date)"
    [class.end-date]="isEndDate(date)"
    [class.faded]="isHovered(date) || isInside(date)"
    (mouseenter)="hoveredDate = date"
    (mouseleave)="hoveredDate = null"
  >
    {{ date.day }}
  </span>
</ng-template>
<ng-template #footerTemplate>
  <hr class="my-0" />
  <div class="action-buttons">
    <button class="btn btn-sm btn-gray" (click)="onClear()">Clear</button>
    <button
      class="btn btn-sm btn-orange"
      (click)="onApply()"
      [disabled]="fromDate && !toDate"
    >
      Apply
    </button>
  </div>
</ng-template>
<!-- end Templates -->
