import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ExportDataService } from '@services/export-data.service'

@Component({
  selector: 'app-download-menu',
  templateUrl: './download-menu.component.html',
  styleUrls: ['./download-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DownloadMenuComponent implements OnInit {

  @Input() title: string;
  @Input() datatype: 'table' | 'detail' = 'table';
  @Input() exportDataFn: Function;

  isDownloading: boolean = false;

  constructor(private exportService: ExportDataService) { 
  }

  ngOnInit(): void {
  }

  async downloadAsCSV() {
    this.isDownloading = true;
    let data = await this.exportDataFn();
    this.exportService.downLoadFile(this.title, data, this.datatype, 'csv');
    this.isDownloading = false;
  }
  async downloadAsPDF() {
    this.isDownloading = true;
    let data = await this.exportDataFn();
    this.exportService.downLoadFile(this.title, data, this.datatype, 'pdf');
    this.isDownloading = false;
  }
  async downloadAsXLSX() {
    this.isDownloading = true;
    let data = await this.exportDataFn();
    // this.exportService.downLoadFile(this.title, this.data?.body, this.datatype, 'xlsx');
    this.exportService.downLoadFile(this.title, data, this.datatype, 'xlsx');
    this.isDownloading = false;
  }

}


