<!-- Page Header Start-->
<div class="page-header" [class.close_icon]="navServices.collapseSidebar">
  <div class="header-wrapper row m-0">
    <div class="header-logo-wrapper">
      <div class="logo-wrapper">
        <a routerLink='/dashboard'>
          <img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="">
          <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="">
        </a>
      </div>      
    </div>
    <div class="left-header horizontal-wrapper px-4 px-md-0">
      <app-menu></app-menu>
    </div>
    <div class="nav-right right-header p-0 ml-auto">
      <ul class="nav-menus m-0">
        <li class="profile-nav onhover-dropdown p-0 m-0">
           <app-my-account></app-my-account>
        </li>
      </ul>
    </div>
  </div>  
</div>
<!-- Page Header Ends -->