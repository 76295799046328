import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-list-item-widget",
  templateUrl: "./list-item-widget.component.html",
  styleUrls: ["./list-item-widget.component.scss"],
})
export class ListItemWidgetComponent implements OnInit {
  @Input() color: string = "#000";
  @Input() textColor: string = "#fff";
  @Input() value: string|number|null = null;
  @Input() title: string = null;

  constructor() {}

  ngOnInit(): void {}
  // adjustSize(data) {
  //   if (!data || data == "") return "inherit";

  //   var dataLength = data.toString().length;

  //   const size = this.getSize(dataLength);
  //   return {
  //     "font-size": size,
  //     "line-height": size,
  //   };
  // }

  // getSize(dataLength: number) {
  //   if (dataLength <= 2) {
  //     return "28px";
  //   } else if (dataLength <= 3) {
  //     return "23px";
  //   } else if (dataLength <= 4) {
  //     return "16px";
  //   } else {
  //     return "22px";
  //   }
  // }
}
