import { Routes } from '@angular/router';


export const content: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    loadChildren: () => import('@components/dashboard').then(m => m.DashboardModule)
  },
  {
    path: 'user-list',
    loadChildren: () => import('@components/user-list').then(m => m.UserListModule)
  },
  {
    path: 'user-management',
    loadChildren: () => import('@components/user-management').then(m => m.UserManagementModule)
  },
  {
    path: 'reports',
    loadChildren: () => import('@components/reports').then(m => m.ReportsModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('@components/profile').then(m => m.ProfileModule)
  }
];
