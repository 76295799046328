import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { DragulaModule } from "ng2-dragula";
import { TranslateModule } from "@ngx-translate/core";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";

// Components
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { FeatherIconsComponent } from "./components/feather-icons/feather-icons.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { ContentComponent } from "./components/layout/content/content.component";
import { FullComponent } from "./components/layout/full/full.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { TapToTopComponent } from "./components/tap-to-top/tap-to-top.component";
// Header Elements Components
import { SearchComponent } from "./components/header/elements/search/search.component";
import { MegaMenuComponent } from "./components/header/elements/mega-menu/mega-menu.component";
import { LanguagesComponent } from "./components/header/elements/languages/languages.component";
import { NotificationComponent } from "./components/header/elements/notification/notification.component";
import { BookmarkComponent } from "./components/header/elements/bookmark/bookmark.component";
import { CartComponent } from "./components/header/elements/cart/cart.component";
import { MessageBoxComponent } from "./components/header/elements/message-box/message-box.component";
import { MyAccountComponent } from "./components/header/elements/my-account/my-account.component";
// Directives
import { DisableKeyPressDirective } from "./directives/disable-key-press.directive";
import { OnlyAlphabetsDirective } from "./directives/only-alphabets.directive";
import { OnlyNumbersDirective } from "./directives/only-numbers.directive";
import { ShowOptionsDirective } from "./directives/show-options.directive";
// Services
import { LayoutService } from "./services/layout.service";
import { NavService } from "./services/nav.service";
import { DataService } from "./services/data.service";
//components
import { NgxDtFooterComponent } from "./components/custom/ngx-dt-footer/ngx-dt-footer.component";
import { KnobChartComponent } from "./components/custom/charts/knob-chart/knob-chart.component";
import { MenuComponent } from "./components/menu/menu.component";
import { DateRangePickerComponent } from "./components/custom/date-range-picker/date-range-picker.component";
import { ListItemWidgetComponent } from "./components/custom/list-item-widget/list-item-widget.component";
import { DownloadMenuComponent } from "./components/custom/download-menu/download-menu.component";
import { DropdownButtonComponent } from "./components/custom/dropdown-button/dropdown-button.component";
import { ConfirmationModalComponent } from "./components/custom/confirmation-modal/confirmation-modal.component";
import { ResourceService } from "./services/resource.service";
import { MetadataService } from "./services/metadata.service";
import { YearDropdownComponent } from "./components/custom/year-dropdown/year-dropdown.component";
import { NgxdtResizerDirective } from './directives/ngxdt-resizer.directive';
import { PageNotFoundComponent } from './components/custom/page-not-found/page-not-found.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    ContentComponent,
    BreadcrumbComponent,
    FeatherIconsComponent,
    FullComponent,
    ShowOptionsDirective,
    DisableKeyPressDirective,
    OnlyAlphabetsDirective,
    OnlyNumbersDirective,
    LoaderComponent,
    TapToTopComponent,
    SearchComponent,
    MegaMenuComponent,
    LanguagesComponent,
    NotificationComponent,
    BookmarkComponent,
    CartComponent,
    MessageBoxComponent,
    MyAccountComponent,
    MenuComponent,
    DateRangePickerComponent,
    ListItemWidgetComponent,
    NgxDtFooterComponent,
    KnobChartComponent,
    DownloadMenuComponent,
    DropdownButtonComponent,
    ConfirmationModalComponent,
    YearDropdownComponent,
    NgxdtResizerDirective,
    PageNotFoundComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgxDatatableModule,
    DragulaModule.forRoot(),
    TranslateModule,
  ],
  providers: [
    NavService,
    LayoutService,
    DataService,
    ResourceService,
    MetadataService,
  ],
  exports: [
    NgbModule,
    NgxDatatableModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    LoaderComponent,
    BreadcrumbComponent,
    FeatherIconsComponent,
    TapToTopComponent,
    DisableKeyPressDirective,
    OnlyAlphabetsDirective,
    OnlyNumbersDirective,
    ListItemWidgetComponent,
    KnobChartComponent,
    DateRangePickerComponent,
    DownloadMenuComponent,
    DropdownButtonComponent,
    ConfirmationModalComponent,
    YearDropdownComponent,
    NgxdtResizerDirective,
  ],
})
export class SharedModule {}
