import { Component, OnInit } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '../auth.service';
import { ResetPasswordRequestModel } from '../auth.model';
import { StorageService } from '@services/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  hidePassword: boolean = true;
  hideConfirmPassword: boolean = true;
  
  userEmail: string = "";
  userToken: string = "";

  isProcessing:boolean = false;
  isRequestSuccess: boolean = false;

  showLoginButton: boolean = false;
  
  resetPasswordRequestModel: ResetPasswordRequestModel = new ResetPasswordRequestModel();

  constructor(
    private authService: AuthService, 
    private storageservice: StorageService,
    private router: Router,
    private activateRouter: ActivatedRoute,
    private toaster: ToastrService) 
  { 
    this.storageservice.removeItem('authorizationDataIdToken');
    this.storageservice.removeItem('user');
    this.authService.isLoggedIn = false;

    this.activateRouter.queryParams.subscribe((params) => {
      this.resetPasswordRequestModel.email = params["email"];
      let token: string = params["token"];
      let re = /\s+/g;
      if(token){
        this.resetPasswordRequestModel.token = token.replace(re, "+");
      }
    });
  }

  ngOnInit(): void {
  }  

  onCancel(){
    this.resetPasswordRequestModel = new ResetPasswordRequestModel();
  }

  onSubmit() {
    this.isProcessing = true;
    if(!this.resetPasswordRequestModel.password){
      this.toaster.error("Password cannot be empty");
      this.isProcessing = false;
      return;
    }
    if(!this.resetPasswordRequestModel.confirmPassword){
      this.toaster.error("Confirm password cannot be empty");
      this.isProcessing = false;
      return;
    }
    if(this.resetPasswordRequestModel.password != this.resetPasswordRequestModel.confirmPassword){
      this.toaster.error("Both passwords must match");
      this.isProcessing = false;
      return;
    }

    this.authService.isLoggedIn = false;

    this.authService.resetPassword(this.resetPasswordRequestModel)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe(res => {
        if (res.isSuccess) {          
          this.isRequestSuccess = true;
          this.showLoginButton = res.response.showLogIn;
        }
        this.isProcessing = false;
    });
  }
  
  private handleError(error: any) {
    this.isProcessing = false;
    if(error.status === 0 && error.statusText === 'Unknown Error'){
      return throwError(error);
    }
    else if(error.error.errors){
      if(error.error?.errors[0] === "Invalid token."){
        this.toaster.error("Link expired");
      }
      else{
        this.toaster.error(error.error.errors[0]);
      }
    }
    else if(error.error.message === "No such email registered"){
      this.toaster.error("Invalid link");
    }
    else{
      this.toaster.error(error.error.message);
    }
    return throwError(error);
  }

}
