import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ContentComponent } from "./shared/components/layout/content/content.component";
import { content } from "./shared/routes/routes";
import { AuthGuard } from "./shared/guard/auth.guard";
import { PageNotFoundComponent } from "./shared/components/custom/page-not-found/page-not-found.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "dashboard",
    pathMatch: "full",
  },
  {
    path: "account",
    loadChildren: () => import("@components/auth").then((m) => m.AuthModule),
  },
  {
    path: "",
    component: ContentComponent,
    canActivate: [AuthGuard],
    children: content,
  },
  {
    path: 'invalid-link',
    component: PageNotFoundComponent,
  },
  {
    path: "**",
    redirectTo: "invalid-link",
  },
];

@NgModule({
  imports: [
    [
      RouterModule.forRoot(routes, {
        anchorScrolling: "enabled",
        scrollPositionRestoration: "enabled",
        relativeLinkResolution: "legacy",
      }),
    ],
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
