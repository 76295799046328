import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";
import { DataService } from "./data.service";
import { DropdownData } from "../models/dropdown-data-model";
import { ResponseModel } from "../models/response.model";
import { Observable } from "rxjs";
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: "root",
})
export class MetadataService {
  //public dropdownData1: Subject<any> = new Subject<any>();
  private dropdownData: DropdownData;

  constructor(
    private service: DataService,
    private storageservice: StorageService
  ) {}

  // get metadata dropdown list
  public getMetadataDropdownList(): DropdownData {
    this.dropdownData = this.storageservice.retrieve("metadata");
    if (this.dropdownData) {
      return this.dropdownData;
    } else {
      return new DropdownData();
    }
  }

  // store metadata DropdownList
  public storeMetadataDropdownList() {
    this.getMetadata().subscribe((res) => {
      this.storageservice.removeItem("metadata");
      this.storageservice.store("metadata", res.response);
    });
  }

  private getMetadata(): Observable<ResponseModel> {
    let url = "/identityapi/admin/Metadata/getDropdownlist";
    return this.service.get(url).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
  // store metadata GetUsersYears
  public getCustomData(): Observable<ResponseModel> {
    let url = "/identityapi/admin/Metadata/GetUsersYears";
    return this.service.get(url).pipe<ResponseModel>(
      tap((response: any) => {
        return response;
      })
    );
  }
}
