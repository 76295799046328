import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

export interface TableData{
  head?: any[];
	body?: any[];
}

@Injectable({
    providedIn: 'root',
})

export class ExportDataService {
  // private exportToCsv(filename: string, rows: object[]) {
  //   if (!rows || !rows.length) {
  //     return;
  //   }
  //   const separator = ',';
  //   const keys = Object.keys(rows[0]);
  //   const csvContent =
  //     keys.join(separator) +
  //     '\n' +
  //     rows.map(row => {
  //       return keys.map(k => {
  //         let cell = row[k] === null || row[k] === undefined ? '' : row[k];
  //         cell = cell instanceof Date
  //           ? cell.toLocaleString()
  //           : cell.toString().replace(/"/g, '""');
  //         if (cell.search(/("|,|\n)/g) >= 0) {
  //           cell = `"${cell}"`;
  //         }
  //         return cell;
  //       }).join(separator);
  //     }).join('\n');

  //   const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  //   if (navigator.msSaveBlob) { // IE 10+
  //     navigator.msSaveBlob(blob, filename);
  //   } else {
  //     const link = document.createElement('a');
  //     if (link.download !== undefined) {
  //       // Browsers that support HTML5 download attribute
  //       const url = URL.createObjectURL(blob);
  //       link.setAttribute('href', url);
  //       link.setAttribute('download', filename);
  //       link.style.visibility = 'hidden';
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     }
  //   }
  // }

  private downloadAsCSV(title: string, data: TableData | any, dataType: 'table' | 'detail') {
    
    const replacer = (key, value) => (value === null ? '' : value);
    // specify how you want to handle null values here
    
    const header = data.head;
    const csv = data.body;
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');
  
    const a = document.createElement('a');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
  
    a.href = url;
    a.download = title + '.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  public downLoadFile(title: string, data: TableData | any, dataType: 'table' | 'detail', downloadType: 'pdf' | 'csv' | 'xlsx' ) {
    // set title, if null
    if (!title) title = dataType + "_report";

    // set report file name
    // let currentDate = new Date().toJSON().slice(0, 10);
    let currentDate = new Date().toISOString().slice(0, 19).replace('T', ' ');
    currentDate = currentDate.replace(' ', '_');
    console.log(currentDate);
    const filename:any = title + "_" + currentDate;

    if (downloadType === 'csv') {
      this.downloadAsCSV(filename, data, dataType);
    }
    else if (downloadType === 'pdf') {
      this.downloadAsPDF(filename, data);
    }
    else if (downloadType === 'xlsx') {
      this.downloadAsXLSX(dataType,filename,data);
    }
    else {
      console.log('download format not supported');
    }
  }

  private downloadAsPDF(filename: string, data: TableData) {
    const doc = new jsPDF('l', 'mm', 'a4');
    autoTable(doc, {
      head: [data.head],
      body: data.body,
      //foot: [[filename]]
    });
    doc.save(filename + '.pdf',);
  }
 
  // public downloadAsXLSX(json: any, filename: string,  data: TableData | any ): void {
  //   // data._internalColumns.name = data.rows;
  //   // let a = data._rows;
  //   // const newArrayOfObj = a.map(({fullname: name,email: emailaddress, ...rest }) => ({ name,emailaddress, ...rest}));
  //   // newArrayOfObj.forEach(a=> delete (a.id));
  //   const d = [data.head, ...data.body];
  //   const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(d);
  //      // code to make First letter Capatial of Header in Xl Sheet
  //   var range = XLSX.utils.decode_range(myworksheet['!ref']);
  //   for(var C = range.s.c; C <= range.e.c; ++C) {
  //     var address = XLSX.utils.encode_col(C) + "1"; // <-- first row, column number C
  //     //var address = XLSX.utils.encode_col(C) + "1"; // <-- first row, column number C
  //     if(!myworksheet[address]) continue;
   
  //     myworksheet[address].v = myworksheet[address].v.charAt(0).toUpperCase() + myworksheet[address].v.slice(1);
  //     // if(!myworksheet[address].v) continue;
  //     // myworksheet[address].v = myworksheet[address].v.charAt(0).toUpperCase() + myworksheet[address].v.slice(1);
  //   } 
  //   // code to make First letter Capatial of Header in Xl Sheet
  //   const myworkbook: XLSX.WorkBook = { Sheets: { 'data': myworksheet }, SheetNames: ['data'] };
  //   const excelBuffer: any = XLSX.write(myworkbook, { bookType: 'xlsx', type: 'array' });
  //   this.saveAsExcelFile(excelBuffer, filename);
  // }

  public downloadAsXLSX(json: any, filename: string, data: TableData | any): void {
     const header = data.head;
     const exportdata = [header, ...data.body];
     const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportdata);
     const range = XLSX.utils.decode_range(myworksheet['!ref']);

     for (let row = range.s.r; row <= range.e.r; row++) {
      for (let column = range.s.c; column <= range.e.c; column++) {
        const currentCellAddress = XLSX.utils.encode_cell({ c: column, r: row });
        const targetCellAddress = XLSX.utils.encode_cell({ c: column, r: row - 1 });
        myworksheet[targetCellAddress] = myworksheet[currentCellAddress];
        delete myworksheet[currentCellAddress];
      }
    }
  
    range.s.r -= 1;
    range.e.r -= 1;    
    const myworkbook: XLSX.WorkBook = { Sheets: { 'data': myworksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(myworkbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, filename);
  }
      
  private saveAsExcelFile(buffer: any, filename: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, filename + EXCEL_EXTENSION);
  }

}