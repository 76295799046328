import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {
  @Input('data') data: any;
  @Input('confirmationModalTemplate') confirmationModalTemplate: ConfirmationModalTemplate = ConfirmationModalTemplate.Default;

  modalTemplate: typeof ConfirmationModalTemplate = ConfirmationModalTemplate;
  modalAction: typeof ModalAction = ModalAction;

  constructor(public activeModal: NgbActiveModal, private modalConfig: NgbModalConfig) {
    this.modalConfig.centered = true;
    this.modalConfig.backdrop = "static";
  }

  ngOnInit(): void {
  }
  closeModal(action: ModalAction) {
    this.activeModal.close(action);
  }

}

export enum ConfirmationModalTemplate {
  ActivateUser,
  DeactivateUser,
  ResetPassword,
  ExtendDownloadTime,
  Default
}

export enum ModalAction{
  Yes,
  No,
  Cancel
}