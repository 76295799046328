<ul class="horizontal-menu">
  <li class="mega-menu">    
    <div class="mega-menu-container horizontal-mega-menu nav-submenu">
      <div class="container-fluid">
        <div *ngIf="!navServices.isMobile" class="row align-items-center justify-content-center">          
          <div class="menu-item" *ngFor="let megaItem of megaItems" [class.active]="megaItem.active">
            <div class="link-section icon" (click)="toggletNavActive(megaItem)">
              <div class="link-header">
                <a [routerLink]="megaItem.path" *ngIf="megaItem.type === 'link'">
                  <span class="link-title">{{megaItem.title}}</span>
                </a>
              </div>
            </div>
          </div>          
        </div>
        <div *ngIf="navServices.isMobile" class="row align-items-center justify-content-center mobile-menu">
          <select class="form-control" name="menuList" (click)="onMenuChange($event.target.value)">
            <option class="text-muted" *ngFor="let megaItem of megaItems" [value]="megaItem.path" [selected]="megaItem.active">
              {{megaItem.title}}
            </option>
          </select>
        </div>
      </div>
    </div>
  </li>  
</ul>