import { Component, OnInit } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { AuthService } from '../auth.service';
import { LoginRequestModel } from '../auth.model';
import { StorageService } from '@services/storage.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MetadataService } from '@src/app/shared/services/metadata.service';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  hidePassword: boolean = true;

  isProcessing: boolean = false;

  loginModel = new LoginRequestModel();
  defaultUserLogin: LoginRequestModel = {
    // email: "admin@ClassKeeper.com",
    // password: "Pass@word1",
    email: '',
    password: '',
    rememberMe: false,
    deviceId: '',
    platformId: 0,
    token: ''
  };

  constructor(
    private authService: AuthService,
    private storageservice: StorageService,
    private router: Router,
    private toaster: ToastrService,
    private metadataService: MetadataService
  ) {
    // this.storageservice.removeItem("authorizationDataIdToken");
    // this.storageservice.removeItem("user");
    const rememberMe = localStorage.getItem('rememberMe');
    if (rememberMe) {
      const storage = (rememberMe == "true") ? localStorage : sessionStorage;
      const token = storage.getItem('authorizationDataIdToken');
      const user = storage.getItem('user');
      if (token && user) {
        this.authService.isLoggedIn = true;
        this.router.navigate(['/dashboard']); //redirect to landing page
      }
    }
    this.storageservice.clear();
    this.authService.isLoggedIn = false;
  }

  ngOnInit(): void {
    this.loginModel = this.defaultUserLogin;
    this.isProcessing = false;
  }

  onReset() {
    this.loginModel = new LoginRequestModel();
  }

  onSubmit() {
    this.isProcessing = true;
    if (this.loginModel.email == "" || this.loginModel.email == undefined) {
      this.toaster.error("email cannot be empty");
      this.isProcessing = false;
      return;
    }
    if (
      this.loginModel.password == "" ||
      this.loginModel.password == undefined
    ) {
      this.toaster.error("password cannot be empty");
      this.isProcessing = false;
      return;
    }

    this.authService.isLoggedIn = false;

    this.authService
      .signIn(this.loginModel)
      .pipe(catchError((err) => this.handleError(err)))
      .subscribe((res) => {
        if (res.isSuccess) {
          this.storageservice.setRememberMe(this.loginModel.rememberMe ? "true" : "false");
          this.storageservice.store("authorizationDataIdToken", res.response.accessToken);
          this.storageservice.store("user", res.response.user);
          this.authService.isLoggedIn = true;
          this.metadataService.storeMetadataDropdownList();
          this.isProcessing = false;
          this.router.navigate(['/dashboard']); //redirect to landing page
        }
        this.isProcessing = false;
      });
  }

  private handleError(error: any) {
    this.isProcessing = false;
    if (error.status === 0 && error.statusText === 'Unknown Error') {
      return throwError(error);
    } else if (error.error.message === "Input validation failed!") {
      this.toaster.error(error.error.errors[0]);
    } else {
      this.toaster.error(error.error.message);
    }
    return throwError(error);
  }
}
