import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Menu, NavService } from '../../services/nav.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})

export class MenuComponent implements OnInit, OnDestroy {  
  
  public megaItems: Menu[];
  public levelmenuitems: Menu[];
  megaItemsSub$: Subscription;
  levelMenuItemsSub$: Subscription;
  activatedRouteSub$: Subscription;
  
  constructor(public navServices: NavService, private router: Router, private activatedRoute: ActivatedRoute) { 
    this.megaItemsSub$ = this.navServices.megaItems.subscribe(megaItems => this.megaItems = megaItems);
    this.levelMenuItemsSub$ = this.navServices.levelmenuitems.subscribe(levelmenuitems => this.levelmenuitems = levelmenuitems);
    
    this.navServices.levelMenu = false;
    this.navServices.megaMenu  = true;

    // get current route
    this.activatedRouteSub$ = this.activatedRoute.firstChild.url.subscribe((data) => {
      const currentPath = data[0]?.path;
      const selectedMenuItem = this.megaItems.find(s=>s.path == ("/" + currentPath))
      this.toggletNavActive(selectedMenuItem);
    });
  }
  ngOnDestroy(): void {
    this.megaItemsSub$.unsubscribe();
    this.levelMenuItemsSub$.unsubscribe();
    this.activatedRouteSub$.unsubscribe();
  }

  ngOnInit() {
    
  }

  megaMenuToggle() {
    this.navServices.levelMenu = false;
    this.navServices.megaMenu  = !this.navServices.megaMenu;
    if(window.innerWidth < 991) { 
      this.navServices.collapseSidebar = true;
    }
  }

  levelMenuToggle() {
    this.navServices.megaMenu  = false;
    this.navServices.levelMenu = !this.navServices.levelMenu;
    if(window.innerWidth < 991) { 
      this.navServices.collapseSidebar = true;
    }
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if(!item) return;

    if (!item.active) {
      this.megaItems.forEach(a => {
        if (this.megaItems.includes(item)) {
          a.active = false;
        }
        if (!a.children) { return false; }
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false;
          }
        });
      });
    }
    item.active = true;
  }

  onMenuChange(path: string){
    const selectedMenu = this.megaItems.find(s=>s.path == path);
    if(selectedMenu){
      this.toggletNavActive(selectedMenu);
    }

    this.router.navigate([path]);
  }
}
