import { Component, OnInit, Input, ViewEncapsulation } from "@angular/core";
import * as feather from "feather-icons";

@Component({
  selector: "app-feather-icons",
  templateUrl: "./feather-icons.component.html",
  styleUrls: ["./feather-icons.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class FeatherIconsComponent implements OnInit {
  @Input("icon") icon;
  @Input("stroke-width") strokeWidth = 2;  
  @Input("margin") margin = "0";
  @Input("width") width = "14";
  @Input("height") height = "14";
  @Input("vertical-align") verticalAlign = "middle";

  constructor() {}

  ngOnInit() {
    setTimeout(() => {
      feather.replace();
    });
  }
}
