import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import {
  NgbDate,
  NgbDateParserFormatter,
  NgbDateStruct,
  NgbInputDatepicker,
} from "@ng-bootstrap/ng-bootstrap";

export class SelectedDates {
  startDate: NgbDate;
  endDate: NgbDate;
}
@Component({
  selector: "app-date-range-picker",
  templateUrl: "./date-range-picker.component.html",
  styleUrls: ["./date-range-picker.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DateRangePickerComponent implements OnInit {

  @Output() selectedDates: EventEmitter<SelectedDates> = new EventEmitter<SelectedDates>();

  maxDate: NgbDateStruct;
  minDate: NgbDateStruct;

  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate | null;
  toDate: NgbDate | null;

  fromDateString: string = "";
  toDateString: string = "";

  inputDate: string = "";

  @ViewChild("datepicker") dp: NgbInputDatepicker;

  constructor(
    public formatter: NgbDateParserFormatter
  ) {
    this.onClear();
  }
  ngOnInit(): void {
    // throw new Error("Method not implemented.");
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (
      this.fromDate &&
      !this.toDate &&
      date &&
      date.after(this.fromDate)
    ) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    this.toDateString = this.formatter.format(this.toDate);
    this.fromDateString = this.formatter.format(this.fromDate);

    this.inputDate =
      this.formatter.format(this.fromDate) +
      " - " +
      this.formatter.format(this.toDate);
  }  
  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }
  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }
  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }
  isStartDate(date: NgbDate) {
    return date.equals(this.fromDate);
  }
  isEndDate(date: NgbDate) {
    return date.equals(this.toDate);
  }
  onClear() {
    this.inputDate = null;
    this.fromDate = null;
    this.toDate = null;
    this.toDateString = null;
    this.fromDateString = null;
  }
  onApply() {
    this.selectedDates.emit({
      startDate: this.fromDate,
      endDate: this.toDate,
    });
    this.dp.close();
  }

  // validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
  // 	const parsed = this.formatter.parse(input);
  // 	return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  // }
}
