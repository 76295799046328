import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';

// Menu
export interface Menu {
	headTitle1?: string,
	headTitle2?: string,
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService implements OnDestroy {
	public isMobile: boolean = false;

	private unsubscriber: Subject<any> = new Subject();
	public  screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

	// Search Box
	public search: boolean = false;

	// Language
	public language: boolean = false;
	
	// Mega Menu
	public megaMenu: boolean = false;
	public levelMenu: boolean = false;
	public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

	// For Horizontal Layout Mobile
	public horizontal: boolean = window.innerWidth < 991 ? false : true;

	// Collapse Sidebar
	public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

	// Full screen
	public fullScreen: boolean = false;

	constructor(private router: Router) {
		this.setScreenWidth(window.innerWidth);
		this.isMobileDevice();

		fromEvent(window, 'resize').pipe(
			debounceTime(500),
			takeUntil(this.unsubscriber)
		).subscribe((evt: any) => {
			this.setScreenWidth(evt.target.innerWidth);
			if (evt.target.innerWidth < 991) {
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			}
			if(evt.target.innerWidth < 1199) {
				this.megaMenuColapse = false;
			}

			this.isMobileDevice();
		});
		if(window.innerWidth < 991) { // Detect Route change sidebar close
			this.router.events.subscribe(event => { 
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			});
		}
	}

	ngOnDestroy() {
		this.unsubscriber.next();
		this.unsubscriber.complete();
	}

	private setScreenWidth(width: number): void {
		this.screenWidth.next(width);
	}

	private isMobileDevice(){
		this.isMobile = window.innerWidth < 500;
	}

	MENUITEMS: Menu[] = [
		// {
		// 	headTitle1: 'Sample', headTitle2: 'Ready Sample Page.',
		// },
		// {
		// 	title: 'Sample Page', icon: 'home', type: 'sub', badgeType: 'success', badgeValue: '2', active: true, children: [
		// 		{ path: '/sample-page', title: 'Sample 1', type: 'link' },
		// 		{ path: 'javascript:void(0);', title: 'Sample 2', type: 'extLink' }
		// 	]
		// },
	];

	MEGAMENUITEMS: Menu[] = [
		{
			title: 'Dashboard', path: '/dashboard', type: 'link', active: true
		},
		{
			title: 'User List', path: '/user-list', type: 'link', active: false
		},
		{
			title: 'User Management', path: '/user-management', type: 'link', active: false
		},
		{
			title: 'Reports', path: '/reports', type: 'link', active: false
		}
	];

	LEVELMENUITEMS: Menu[] = [
		// {
		// 	path: 'javascript:void(0);', title: 'File Manager', icon: 'git-pull-request', type: 'extLink'
		// },
		// {
		// 	title: 'Users', icon: 'users', type: 'sub', active: false, children: [
		// 		{ path: 'javascript:void(0);', title: 'All Users', icon: 'users', type: 'extLink' },
		// 		{ path: 'javascript:void(0);', title: 'User Profile', icon: 'users', type: 'extLink' },
		// 		{ path: 'javascript:void(0);', title: 'Edit Profile', icon: 'users', type: 'extLink' },
		// 	]
		// },
		// { path: 'javascript:void(0);', title: 'Bookmarks', icon: 'heart', type: 'extLink' },
		// { path: 'javascript:void(0);', title: 'Calender', icon: 'calendar', type: 'extLink' },
		// { path: 'javascript:void(0);', title: 'Social App', icon: 'zap', type: 'extLink' }
	];

	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
	megaItems = new BehaviorSubject<Menu[]>(this.MEGAMENUITEMS);
	levelmenuitems = new BehaviorSubject<Menu[]>(this.LEVELMENUITEMS);

}
