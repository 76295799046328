import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { MetadataService } from "@src/app/shared/services/metadata.service";

@Component({
  selector: "app-year-dropdown",
  templateUrl: "./year-dropdown.component.html",
  styleUrls: ["./year-dropdown.component.scss"],
})
export class YearDropdownComponent implements OnInit {
  @Output() newItemEvent = new EventEmitter<string>();
  @Output() myOutput: EventEmitter<string> = new EventEmitter();
  startYear = new Date().getFullYear();
  range = [];
  display = true;
  selectedObject: any;

  constructor(public metadataService: MetadataService) {}

  ngOnInit(): void {
    this.metadataService.getCustomData().subscribe((res) => {
      console.log(res);
      if (res.isSuccess) {
        this.range = res.response;
      }
    });
  }
  handleChange(index) {
    this.selectedObject = this.range[index - 1];
    console.log(this.selectedObject);
    this.myOutput.emit(this.selectedObject);
  }
}
