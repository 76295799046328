export class User {}

export class LoggedInUserVM {
  id: string;
  userName: string;
  firstName: string;
  lastName: string;
  email: string;
  timeZone: string;
  userRole: string;
  deviceToken: string;
  description: string;
  userStatus: string;
  isLoggedIn: string;
  photo: PhotoModel = new PhotoModel();
  userSubscriptionStatus: string;
  UserSubscriptionStatusId: number;
}
export class PhotoModel {
  id: number | null;
  mainUrl: string;
  editedUrl: string;
  thumbUrl: string;
  photoDetails: PhotoPositionModel = new PhotoPositionModel();
}
export class PhotoPositionModel {
  chipShapeId: number | null;
}

export class UserVM {
  id: string;
  userName: string;
  firstName: string;
  lastName: string;
  photo: PhotoResponse = new PhotoResponse();
  email: string;
  userRole: string;
  userStatus: string;
  userSubscriptionStatus: string;
  createdDate: string;
}

export class PhotoResponse {
  id: number | null;
  mainUrl: string;
  editedUrl: string;
  thumbUrl: string;
}
